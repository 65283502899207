var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "k-card",
    [
      _vm.loader
        ? _c("loader")
        : _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "animated fadeIn" },
                [
                  _vm.findGroups("ACL:TED:REQUEST_FROM_CREDIT")
                    ? _c(
                        "b-btn",
                        {
                          staticClass: "float-right",
                          attrs: { size: "sm", variant: "outline-success" },
                          on: { click: _vm.tedRequest },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("payments.manageTed.requestTed")) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "pt-1" }, [
                    _c("p", { staticClass: "k-card-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("drivers.wallet.balance_available")) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "media" }, [
                      _c("div", { staticClass: "media-body" }, [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "font-weight-bold text-uppercase section-title text-success",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setPriceFormat(
                                    _vm.$t("payments.labels.currency"),
                                    _vm.balance
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("img", {
                        attrs: {
                          src: require("@assets/kovi-wallet-green.svg"),
                        },
                      }),
                    ]),
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "pt-1" }, [
                    _c("p", { staticClass: "k-card-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("drivers.wallet.total_pre_payment")) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "media" }, [
                      _c("div", { staticClass: "media-body" }, [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "font-weight-bold text-uppercase section-title text-success",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setPriceFormat(
                                    _vm.$t("payments.labels.currency"),
                                    _vm.total_pre_payment
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("img", {
                        attrs: {
                          src: require("@assets/kovi-wallet-green.svg"),
                        },
                      }),
                    ]),
                  ]),
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "pt-1" },
                    [
                      _c("p", { staticClass: "k-card-title" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.labels.outstandingDebts")) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "media-body" }, [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "font-weight-bold text-uppercase section-title text-danger",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("money")(_vm.debitTotal)) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c("img", {
                          attrs: { src: require("@assets/kovi-bill-red.svg") },
                        }),
                      ]),
                      _c(
                        "b-link",
                        {
                          staticClass: "btn btn-link p-0 mt-1 font-weight-bold",
                          attrs: {
                            href: `/drivers/profile/${_vm.driverId}/negotiations`,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("payments.labels.negotiateDebts")) +
                              " "
                          ),
                          _c("i", { staticClass: "fa fa-external-link" }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("hr"),
                ],
                1
              ),
              _c("p", { staticClass: "k-card-title pt-1" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("payments.labels.latestTransactions")) +
                    " "
                ),
              ]),
              _c("timeline", {
                attrs: {
                  "timeline-items": _vm.timelineItems,
                  "message-when-no-items": _vm.messageWhenNoItems,
                },
              }),
              _c(
                "b-btn",
                {
                  staticClass: "p-0 mb-3 mt-2 btn-block",
                  attrs: {
                    to: { name: "BalanceHistory" },
                    variant: "outline-primary",
                    block: "",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("payments.labels.viewFullHistory")) +
                      " "
                  ),
                ]
              ),
              _c("hr"),
              _vm.profile.id
                ? _c("driver-payment-methods", {
                    attrs: { profile: _vm.profile },
                  })
                : _vm._e(),
              _c(
                "b-btn",
                {
                  staticClass: "p-0 mb-3 mt-1",
                  attrs: {
                    to: `/drivers/profile/${_vm.driverId}/payment-methods`,
                    type: "button",
                    variant: "outline-primary",
                    block: "",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("payments.labels.managePaymentMethods")) +
                      " "
                  ),
                ]
              ),
              _c("hr"),
              _vm.$regions.isBrazil()
                ? _c("driver-bank-details", {
                    attrs: { "driver-id": _vm.driverId },
                    on: { account: _vm.setDriverAccount },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }