<template>
  <k-card>
    <loader v-if="loader" />
    <div v-else>
      <div class="animated fadeIn">
        <b-btn
          v-if="findGroups('ACL:TED:REQUEST_FROM_CREDIT')"
          size="sm" variant="outline-success" class="float-right"
          @click="tedRequest"
        >
          {{ $t("payments.manageTed.requestTed") }}
        </b-btn>

        <div class="pt-1">
          <p class="k-card-title">
            {{ $t('drivers.wallet.balance_available') }}
          </p>
          <div class="media">
            <div class="media-body">
              <h3 class="font-weight-bold text-uppercase section-title text-success">
                {{ setPriceFormat($t('payments.labels.currency'), balance) }}
              </h3>
            </div>
            <img src="@assets/kovi-wallet-green.svg">
          </div>
        </div>

        <hr>
        <div class="pt-1">
          <p class="k-card-title">
            {{ $t('drivers.wallet.total_pre_payment') }}
          </p>
          <div class="media">
            <div class="media-body">
              <h3 class="font-weight-bold text-uppercase section-title text-success">
                {{ setPriceFormat($t('payments.labels.currency'), total_pre_payment) }}
              </h3>
            </div>
            <img src="@assets/kovi-wallet-green.svg">
          </div>
        </div>
        <hr>
        <div class="pt-1">
          <p class="k-card-title">
            {{ $t('payments.labels.outstandingDebts') }}
          </p>
          <div class="media">
            <div class="media-body">
              <h3 class="font-weight-bold text-uppercase section-title text-danger">
                {{ debitTotal | money }}
              </h3>
            </div>
            <img src="@assets/kovi-bill-red.svg">
          </div>
          <b-link :href="`/drivers/profile/${driverId}/negotiations`" class="btn btn-link p-0 mt-1 font-weight-bold">
            {{ $t('payments.labels.negotiateDebts') }}
            <i class="fa fa-external-link" />
          </b-link>
        </div>
        <hr>
      </div>

      <p class="k-card-title pt-1">
        {{ $t('payments.labels.latestTransactions') }}
      </p>

      <timeline
        :timeline-items="timelineItems"
        :message-when-no-items="messageWhenNoItems"
      />
      <b-btn
        :to="{name: 'BalanceHistory'}"
        variant="outline-primary"
        block
        class="p-0 mb-3 mt-2  btn-block"
      >
        {{ $t('payments.labels.viewFullHistory') }}
      </b-btn>

      <hr>

      <driver-payment-methods
        v-if="profile.id"
        :profile="profile"
      />

      <b-btn
        :to="`/drivers/profile/${driverId}/payment-methods`"
        type="button"
        variant="outline-primary"
        block
        class="p-0 mb-3 mt-1"
      >
        {{ $t('payments.labels.managePaymentMethods') }}
      </b-btn>

      <hr>

      <driver-bank-details
        v-if="$regions.isBrazil()"
        :driver-id="driverId"
        @account="setDriverAccount"
      />
    </div>
  </k-card>
</template>

<script>
import { makeQuery, makeMutation } from '@graphql/middleware'
import DRIVER_GET_BALANCE from '@graphql/driver/queries/get-balance.gql';
import BALANCE_HISTORY from '@graphql/transaction/queries/history.gql';
import TED_REQUEST from '@graphql/ted/mutations/make-ted-request.gql';
import { formatPrice } from '@utils/numbers';
import { parseType } from '@utils/balanceHistory';
import { mapGetters } from 'vuex'
export default {
  name: 'DriverWalletAside',
  components: {
    KCard: () => import('@components/shared/k-card'),
    Loader: () => import('@components/shared/loader'),
    Timeline: () => import('@components/drivers/payment-info/driver-timeline-history'),
    DriverPaymentMethods: () => import('@components/drivers/payment-info/driver-payment-methods'),
    DriverBankDetails: () => import('@components/drivers/payment-info/driver-bank-details')
  },
  props: {
    driverId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      balance: 0,
      total_pre_payment: 0,
      loader: true,
      messageWhenNoItems: 'Não há items disponíveis',
      timelineItems: [],
      accountBank: null
    }
  },
  computed: {
    ...mapGetters('driver', {
      profile: 'header'
    }),
    ...mapGetters('user', ['attributes', 'findGroups']),
    debitTotal () {
      const debit_total = this.profile.transaction_summary?.debit_total
      if (!debit_total || debit_total < 0) return 0
      return debit_total
    }
  },
  mounted () {
    this.getTransactions()
    this.getBalanceHistoryResume()
  },
  methods: {
    setParseType: parseType,
    openModalTransaction () {
      this.$emit('goTo')
    },
    async getTransactions () {
      this.loader = true;
      try {
        const response = await this.$apollo.query({
          query: DRIVER_GET_BALANCE,
          variables: {
            driver: this.driverId
          },
        });
        this.balance = response.data.getBalance.balance_amount
        this.total_pre_payment = response.data.getBalance.total_pre_payment
        this.loader = false;
      } catch (err) {
        this.$log.logError(err);
      }
    },
    getBalanceHistoryResume () {
      makeQuery(
        BALANCE_HISTORY,
        {
          driver: this.driverId,
          page: 0,
          limit: 3
        }
      )
      .then(({data}) => {
        let historyBalance = data.getBalanceHistory.items.map((history) => {
          return {
            title: `${this.setParseType(history.type)} - ${this.setPriceFormat(this.$t('payments.labels.currency'), history.value)}`,
            description: history.inserted_at,
            status: history.type.toUpperCase()
          }
        })
        this.timelineItems = historyBalance;
      })
    },
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value);
    },
    tedRequest () {
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });
      confirmationWindow({
        title: this.$t("payments.manageTed.alertConfirmation"),
        text: this.$t("payments.manageTed.textRequestTed"),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      }).then(result => {
        if (!result.value) return;
        if (!this.accountBank) {
          this.showErrorModal('Motorista não possui uma conta bancária cadastrada')
          return
        }
        makeMutation(
          TED_REQUEST,
            {
              accountBankId: this.accountBank,
              driverID: this.driverId,
              requestedBy: this.attributes.email,
            }
          )
          .then(() => {
            this.showSuccessModal()
            this.$emit('refresh')
          })
          .catch(() => {
            this.showErrorModal()
          })
      })
    },
    showSuccessModal(msg = this.$t("payments.manageTed.textAlertSuccess")) {
      this.$swal({
        type: 'success',
        title: msg,
        showConfirmButton: false,
        timer: 2500,
      })
    },
    showErrorModal(msg = this.$t("payments.manageTed.textAlertError")) {
      this.$swal({
        type: 'error',
        title: 'Ocorreu um erro!',
        text: msg,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    setDriverAccount (account) {
      this.accountBank = account?.id
    }
  }
}
</script>

<style scoped>
.btn-border-left {
  padding-left: 0px !important;
}
hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

.k-card-title {
  color: rgb(158, 160, 165);
  font-size: 12px !important;
  font-weight: 500;
  /* height: 18px; */
  line-height: 18px;
  text-transform: uppercase;
}
</style>
